/* You can add global styles to this file, and also import other style files */
/*
@tailwind base;
@tailwind components;
@tailwind utilities;
*/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

@font-face {
  font-family: silka-medium;
  src: url("/assets/font/silka-medium-webfont.ttf") format("opentype");
}
@font-face {
  font-family: silka-bold;
  src: url("/assets/font/silka-bold-webfont.ttf") format("opentype");
}
*{
  font-family: 'silka-medium';
}
.silka-medium{
  font-family: 'silka-medium';
}
.silka-bold{
  font-family: 'silka-bold';
}

.color{
    color: #C4C4C4;
}

//paginacion
.ngx-pagination a, .ngx-pagination button {
  background: black !important;
  color:#C4C4C4 !important
}

.ngx-pagination .pagination-next a:after, .ngx-pagination .pagination-next.disabled:after {
  content: url('/assets/icons/arrow-right.png') !important;
  background: black;
  width: 25px !important;
  height:25px !important;
  transform: scale(70%);
 
}

.ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled:before {
  content: url('/assets/icons/arrow-left.png') !important;
  background: black;
  width: 25px !important;
  height:25px !important;
  transform: scale(70%);
  
}

.ngx-pagination .current {
  background: none !important;
  color: #FF0000 !important;
  font-size: 20px !important;
}
.ngx-pagination a, .ngx-pagination button {
  font-size: 20px !important;
  display: flex !important;
}

.polite span{
  font-size: 18px !important;
}

.ngx-pagination {
  display: flex !important;
  align-items: center !important;
 
}

.ngx-pagination li {
  display: flex !important;
}

.ngx-pagination .small-screen{
  display: none !important;
}

.pagination-previous {
  margin-right: 12px !important;
}